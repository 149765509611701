import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"
import SiteImg1 from "../assets/img/integration.png"
import SiteImg2 from "../assets/img/time-saver.png"
import SiteImg3 from "../assets/img/component-reuse.png"


import CTA from "../components/cta"
import { FaClock, FaCog, FaFileExport } from "react-icons/fa"


const FeaturesPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout type="features">
        <SEO title="Prices" />



        <section className="features-short-section">

          <div className="uk-panel uk-text-center">
              <div className="header-features-container">
                { /* four cards */}
                <div className="header-features-card">
                  <FaFileExport className="section-img"/>
                  <h2>Powerful integration</h2>
                  <p>Est magna laboris eu eu anim excepteur Lorem proident est elit eiusmod.</p>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-features-card">
                  <FaClock className="section-img"/>
                  <h2>It saves you time</h2>
                  <p>Est magna laboris eu eu anim excepteur Lorem proident est elit eiusmod.</p>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-features-card">
                  <FaCog className="section-img"/>
                  <h2>Not just another CRM</h2>
                  <p>Est magna laboris eu eu anim excepteur Lorem proident est elit eiusmod.</p>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                

              </div>
            </div>

        </section>
        

        <section className="img-text-section" >
          
          <div data-uk-grid>
            <div className="uk-width-1-2@m uk-text-center section-img-container">
              <img className="section-img " src={SiteImg1} alt="SiteImg1"></img>
            </div>
    
    
            <div className="uk-width-1-2@m">
              <div className="section-content">
                <h2 className="section-title">Powerful integration</h2>
                <div className="section-subtitle"><b>Reprehenderit</b> officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">Reprehenderit officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">Reprehenderit officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">
                <a href="#0" className="cta-primary-btn dark">More details</a>
                </div>
                
              </div>
            </div>
          </div>
        </section>

        <section className="img-text-section">

          <div data-uk-grid>
            <div className="uk-width-1-2@m uk-text-center section-img-container uk-flex-last@m">
              <img className="section-img " src={SiteImg2} alt="SiteImg2" width="350"></img>
            </div>
    
            <div className="uk-width-1-2@m ">
              <div className="section-content">
                <h2 className="section-title">Amazing time saver</h2>
                <div className="section-subtitle">Reprehenderit officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">
                <a href="#0" className="cta-primary-btn dark">More details</a>
                </div>
              </div>
            </div>
          </div>
          
        </section>

        <section className="img-text-section">

          <div data-uk-grid>
            <div className="uk-width-1-2@m uk-text-center section-img-container">
              <img className="section-img " src={SiteImg3} alt="SiteImg3" width="350"></img>
            </div>
    
            <div className="uk-width-1-2@m ">
              <div className="section-content">
                <h2 className="section-title">Build it once, use it again &amp; again</h2>
                <div className="section-subtitle">Reprehenderit officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
              </div>
            </div>
          </div>
          
        </section>


        <CTA type="cta" url="#0"/>

        <section className="faq-section">
          <div className="faq-container">
            <div className="uk-card uk-card-muted uk-width-1-2@m uk-align-center">
              <div>
                <h3 className="uk-text-center">FAQs</h3>
              </div>
              <div data-uk-accordion className="faq-accordion">
                
                    <li>
                      <a className="uk-accordion-title faq-question" href="#0">
                        Here's the question
                      </a>
                      <div className="uk-accordion-content faq-answer">Here's the answer</div>
                    </li>
                    <li>
                      <a className="uk-accordion-title faq-question" href="#0">
                        Here's the second question
                      </a>
                      <div className="uk-accordion-content faq-answer">Here's the answer</div>
                    </li>
                 
              </div>
            </div>
          </div>
        </section>

        
        

      </Layout>

    )}
  
  />
)

export default FeaturesPage
